<script setup lang="ts">
import { ModalDefaultOrBottom, ModalCloseWhite } from '@/shared/ui/modal'

import { appsflyerDeeplinkEntity } from '@/entities/appsflyer-deeplink'

import { DownloadAppBlock } from '@/features/download-app'

type State = {
  open: boolean
}

const $modal = useModal()
const deeplink = appsflyerDeeplinkEntity.model.useDeeplink()
let qrcode: any
const qrcodeSrc = ref()

const state = reactive<State>({
  open: false,
})

onMounted(async () => {
  $modal.onOpen('download-app', onOpen)
  // @ts-ignore
  qrcode = await import('qrcode')
})

onBeforeUnmount(() => {
  $modal.off('download-app', 'open')
})

async function onOpen() {
  state.open = true
  qrcodeSrc.value = await qrcode.toDataURL(deeplink.createLink(), {
    errorCorrectionLevel: 'L',
  })
}

function onClose() {
  state.open = false
  $modal.close('download-app')
}
</script>

<template>
  <ModalDefaultOrBottom v-model="state.open" background="bg-gray-200" @on-overlay-click="onClose">
    <template #header>
      <div class="flex justify-end" @click="onClose">
        <ModalCloseWhite />
      </div>
    </template>
    <DownloadAppBlock position="profile" />
    <div class="w-192 h-192 rounded-8 custom-shadow flex mx-auto mb-64 justify-center items-center">
      <img v-if="qrcodeSrc" :src="qrcodeSrc" class="w-inherit h-inherit rounded-inherit" />
    </div>

    <p class="text-center text-h5-semibold text-gray-800">
      {{ $t('download_app.text') }}
    </p>
  </ModalDefaultOrBottom>
</template>

<style scoped>
.custom-shadow {
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.3);
}
</style>
